.likeDislikeWrapper svg {
    transition: transform 0.3s ease;
    cursor: pointer;
    width: 10vw;
    height: 7vw;
}

.likeDislikeWrapper svg:hover {
    transform: scale(1.2);
}

@media (max-width: 768px) {
    .likeDislikeWrapper svg {
        width: 30vw;
        height: 20vw;
    }
}
