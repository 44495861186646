.downloadBtn {
    position: absolute;
    bottom: 0;
    transform: translate(-75%, 25%);
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    opacity: 1;
    width: 3vw;
}

@media (min-width: 500px) and (max-width: 800px) {
    .dlFilesContainer {
        width: 40%;
        height: 40%;
        max-height: 40%;
        max-width: 40%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 34vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
        border-radius: 8px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 50%;
        }

    .downloadBtn {
        width: 7vw;
    }
}

@media (min-width: 800px) {
    .dlFilesContainer {
        width: 20%;
        height: 20%;
        max-height: 20%;
        max-width: 20%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 17vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
        border-radius: 8px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 25%;
        }

    .downloadBtn {
        width: 4vw;
    }
}

@media (max-width: 500px) {
    .dlFilesContainer {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 68vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
        border-radius: 8px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 100%;
        }

    .downloadBtn {
        width: 10vw;
    }
}

.dlFilesContainer:hover {
    transition: all ease 0.3s;
    transform: translateY(-8px);
}


