.trueFalseWrapper svg {
    transition: transform 0.3s ease;
    cursor: pointer;
    width: 10vw;
    height: 7vw;
}

    .trueFalseWrapper svg:hover {
        transform: scale(1.2);
    }

@media (max-width: 768px) {
    .trueFalseWrapper svg {
        width: 30vw;
        height: 20vw;
    }
}
