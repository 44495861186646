.clicked {
    transition: all ease 0.3s;
    opacity: 1 !important;
}

.checked {
    position: absolute;
    bottom: 0;
    transform: translate(-75%, 25%);
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    opacity: 0;
    width: 3vw;
}

@media (min-width: 500px) and (max-width: 800px){
    .voteContainer {
        width: 40%;
        height: 40%;
        max-height: 40%;
        max-width: 40%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .imageVote {
        object-fit: contain;
        max-width: 100%;
        max-height: 34vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
        border-radius: 8px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .wrapper > div {
        flex: 1 1 50%;
    }

    .checked {
        width: 7vw;
    }
}

@media (min-width: 800px) {
    .voteContainer {
        width: 20%;
        height: 20%;
        max-height: 20%;
        max-width: 20%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .imageVote {
        object-fit: contain;
        max-width: 100%;
        max-height: 17vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
        border-radius: 8px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 25%;
        }

    .checked {
        width: 4vw;
    }
}

@media (max-width: 500px) {
    .voteContainer {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        position: relative;
        padding: 35px 40px;
        align-self: center;
    }

    .imageVote {
        object-fit: contain;
        max-width: 100%;
        max-height: 68vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
        border-radius: 8px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 100%;
        }

    .checked {
        width: 10vw;
        transform: translate(-75%, -50%);
    }
}

.voteContainer:hover {
    transition: all ease 0.3s;
    transform: translate3d(0, -8px, 0);
    backface-visibility: hidden;
    will-change: transform;
}

.voteContainer:hover > :last-child:not(.clicked) {
    opacity: 0.5;
}


