.star {
    transition: transform 0.3s ease-in-out;
    margin: 0 0.5vw;
    width: 5vw;
    height: 5vw;
}

@media (max-width: 768px) {
    .star {
        margin: 0 2vw;
        width: 10vw;
        height: 10vw;
    }
}
