@media (min-width: 500px) and (max-width: 800px){

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 34vw;
        //filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    }

    .wrapper > div {
        flex: none;
    }
}

@media (min-width: 800px) {

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 17vw;
        //filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    }

    .wrapper > div {
        flex: none;
    }
}

@media (max-width: 500px) {

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 68vw;
        //filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    }

    .wrapper > div {
        flex: none;
    }
}

.rateContainer:hover {
    transition: all ease 0.3s;
}

    .rateContainer:hover > :last-child:not(.clicked) {
        opacity: 1 !important;
    }

.clicked {
    transition: all ease 0.3s;
    opacity: 1 !important;
}

.check {
    position: absolute;
    transform : translate(-50%, -50%);
    opacity: 0;
}
